// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-single-article-js": () => import("C:\\xampp\\htdocs\\astraea-frontline\\src\\components\\singleArticle.js" /* webpackChunkName: "component---src-components-single-article-js" */),
  "component---src-components-multiple-article-js": () => import("C:\\xampp\\htdocs\\astraea-frontline\\src\\components\\multipleArticle.js" /* webpackChunkName: "component---src-components-multiple-article-js" */),
  "component---src-components-article-gallery-js": () => import("C:\\xampp\\htdocs\\astraea-frontline\\src\\components\\articleGallery.js" /* webpackChunkName: "component---src-components-article-gallery-js" */),
  "component---src-pages-404-js": () => import("C:\\xampp\\htdocs\\astraea-frontline\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("C:\\xampp\\htdocs\\astraea-frontline\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

